.error-image-overlay{
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.error-image-container{
  display: inline-block;
  background-image: 'https://i.imgur.com/yW2W9SC.png';
  background-size: cover;
  background-position: center;
  width: 40vh;
  height: 40vh;
}

.error-image-text{
  font-size: 28px;
  color: #2f8e89;
}