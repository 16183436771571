@import "../../styles/variables.scss";
@import "../../styles/fonts.scss";
// Most copied from homepage repository

$highlight: #2ec973;
$gray: #abaaaa;

$breakpoints: (
  "mobile" "(max-width: 700px)",
  "tablet" "(min-width: 701px) and (max-width: 1025px)",
  "portable" "(max-width: 1025px)",
  "desktop" "(min-width: 1026px)"
) !default;

@mixin media($media-query) {
  $breakpoint-found: false;

  @each $breakpoint in $breakpoints {
    $name: nth($breakpoint, 1);
    $declaration: nth($breakpoint, 2);

    @if $media-query==$name and $declaration {
      $breakpoint-found: true;

      @media only screen and #{$declaration} {
        @content;
      }
    }
  }

  @if $breakpoint-found==false {
    @warn "Breakpoint ‘#{$media-query}’ does not exist";
  }
}

@include media(portable) {
  .hidden-portable {
    display: none;
  }
}

.header {
  background-color: $clr-white;
  font-family: "Lato";
  height: 120px;
  left: 0;
  line-height: 1.15;
  padding: 40px 20px 0;
  position: relative;
  top: 0;
  transition: top 0.3s ease-in-out;
  width: 100%;
  z-index: 1000;

  @include media(portable) {
    height: 65px;
    padding: 10px 20px 0;
  }

  &.invisible {
    top: -120px;
    transition: top 0.3s ease-in-out;

    @include media(portable) {
      top: -65px;
    }
  }

  &-logo {
    background: url("../../images/svg/logo.svg") 0 0 no-repeat;
    background-size: 155px 30px;
    display: block;
    height: 30px;
    margin-top: 8px;
    position: relative;
    width: 155px;
    z-index: 2;
  }

  &-menu {
    background: transparent;
    border: 0;
    height: 22px;
    position: absolute;
    right: 10px;
    top: 18px;
    width: 22px;
    z-index: 4;

    @include media(desktop) {
      display: none;
    }

    &:before,
    &:after,
    &-line {
      background-color: $clr-purple;
      content: "";
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transition: all 0.3s ease-in-out;
      width: 22px;
    }

    &:before {
      top: 5px;
    }

    &:after {
      bottom: 7px;
    }

    &-line {
      opacity: 1;
      top: 20px;
    }

    &.active {
      &:before,
      &:after,
      &-line {
        transition: all 0.3s ease-in-out;
        width: 22px;
      }

      &:before {
        top: 10px;
        transform: rotate(45deg);
      }

      &:after {
        left: 50%;
        width: 0;
      }

      .header-menu-line {
        top: 10px;
        transform: rotate(-45deg);
      }
    }
  }
}

.navigation {
  left: 0;
  position: absolute;
  text-align: center;
  top: 35px;
  width: 100%;

  &.active {
    display: block;
  }

  @include media(desktop) {
    display: block;
  }

  @include media(portable) {
    background-color: #f9fbfe;
    border-top: 1px solid $clr-gray-200;
    height: calc(100vh - 64px);
    left: 0;
    overflow: scroll;
    position: fixed;
    text-align: left;
    top: 64px;
    width: 100%;
    z-index: 10;
  }
}

.nav {
  &-main {
    .menu {
      list-style: none;
      padding: 0;

      @include media(portable) {
        display: flex;
        flex-direction: column;
      }

      >.menu-item {
        display: inline-block;

        &-mobile {
          display: none;

          @include media(portable) {
            display: inline-block;
          }
        }

        >a {
          color: $clr-menu-link;
          cursor: pointer;
          display: block;
          font-size: 15px;
          font-weight: 600;
          line-height: 50px;
          margin: 0 15px;
          text-decoration: none;
          transition: color 0.3s ease-in-out;

          @include media(desktop) {
            &:hover {
              color: $clr-purple;
              transition: color 0.3s ease-in-out;
            }
          }
        }

        @include media(portable) {
          border-bottom: 1px solid $clr-gray-200;
          order: 2;
          width: 100%;

          >a {
            color: $clr-main-light;
            font-size: 16px;
            line-height: 60px;
          }
        }
      }

      &-item-has-children {
        display: none;
        position: relative;
        transition: 2s;

        &.active {
          .submenu {
            display: flex;
          }
        }

        &.hide {
          .submenu {
            display: none;
          }
        }

        @include media(desktop) {
          &:not(.disable-hover):hover .submenu {
            display: flex;
          }
        }

        >a {
          &:after {
            background: $clr-purple-175 url("../../images/svg/ico-arrow.svg") center center no-repeat;
            background-size: 10px 5px;
            border-radius: 50%;
            content: "";
            display: inline-block;
            height: 16px;
            margin-left: 10px;
            position: relative;
            top: 3px;
            width: 16px;
          }
        }
      }

      .mside {
        @include media(desktop) {
          position: absolute;
          top: 0;
        }

        @include media(portable) {
          border: 0;
          margin: 10px 0;
          text-align: center;
        }

        &-btn {
          right: 0;

          >a {
            background-color: $highlight;
            border-radius: 4px;
            color: $clr-white;
            padding: 0 40px;
            transition: all 0.3s ease-in-out;


            &:hover {
              background-color: lighten($highlight, 10%);
              color: $clr-white;
              transition: all 0.3s ease-in-out;
            }
          }

          @include media(portable) {
            margin-top: 20px;
          }
        }

        &-simple {
          right: 170px;
        }
      }
    }

    .submenu {
      list-style: none;
      padding: 0;

      &::after {
        content: "";
        height: 25px;
        position: absolute;
        top: -20px;
        width: 100%;
      }

      @include media(portable) {
        display: none;
      }

      .menu-item {
        a {
          align-items: center;
          color: $clr-menu-link;
          display: flex;
          flex-wrap: wrap;
          font-size: 16px;
          font-weight: 700;
          text-decoration: none;
          transition: color 0.3s ease-in-out;


          * {
            width: 100%;
          }

          &:hover {
            color: $clr-purple;
            transition: color 0.3s ease-in-out;


            .menu-item-description {
              color: $clr-menu-link;
              transition: color 0.3s ease-in-out;

            }
          }

          .menu-item-description {
            margin: 0;
          }
        }

        &-description {
          color: $clr-main-light;
          font-size: 15px;
          font-weight: 400;
          line-height: 21px;
          transition: color 0.3s ease-in-out;

        }
      }

      @include media(desktop) {
        background-color: $clr-white;
        border-radius: 5px;
        box-shadow: 0 2px 45px 0 rgba(111, 35, 129, 0.24);
        display: none;
        flex-wrap: wrap;
        left: -255px;
        padding: 10px 0 30px;
        position: absolute;
        text-align: left;
        top: 55px;
        width: 670px;
        z-index: 5;

        &:before {
          border-color: transparent transparent $clr-white transparent;
          border-style: solid;
          border-width: 0 14px 14px 14px;
          content: "";
          height: 0;
          left: calc(50% - 14px);
          position: absolute;
          top: -12px;
          width: 0;
        }

        .menu-item {
          padding: 0 24px;
          width: 50%;

          a {
            border-bottom: 1px solid $clr-gray-200;
            min-height: 110px;
            padding: 13px 0 13px 90px;
          }
        }
      }

      @include media(portable) {
        display: none;
        flex-direction: column;

        .menu-item {
          a {
            font-size: 18px;
            min-height: 75px;
            padding: 0 0 0 95px;
          }
        }

        .menu-item-description {
          display: none;
        }
      }
    }

    .micon {
      a {
        background: url("../../images/ico-sprite.png") left center no-repeat;
      }

      &.advisors-icon {
        a {
          background: url("../../images/advisors-icon.png") left center no-repeat;
        }
      }

      &.plan-b {
        a {
          background: url("../../images/plan-b.png") left center no-repeat;
        }
      }

      &-invest a {
        background-position: 0 0;
      }

      &-trade a {
        background-position: 0 -110px;
      }

      &-token a {
        background-position: 0 -330px;
      }

      &-money a {
        background-position: 0 -440px;
      }

      &-companies a {
        background-position: 0 -550px;
      }

      &-bfex a {
        background-position: 0 -660px;
      }

      &-about a {
        background-position: 0 -770px;
      }

      &-knowledge a {
        background-position: 0 -880px;
      }

      &-careers a {
        background-position: 0 -990px;
      }

      &-blog a {
        background-position: 0 -1100px;
      }

      &-community a {
        background-position: 0 -1210px;
      }

      @include media(portable) {
        a {
          background-size: 61px 1020px;
        }

        &-invest a {
          background-position: 20px 0;
        }

        &-trade a {
          background-position: 20px -85px;
        }

        &-token a {
          background-position: 20px -255px;
        }

        &-money a {
          background-position: 20px -340px;
        }

        &-companies a {
          background-position: 20px -425px;
        }

        &-bfex a {
          background-position: 20px -510px;
        }

        &-about a {
          background-position: 20px -595px;
        }

        &-knowledge a {
          background-position: 20px -680px;
        }

        &-careers a {
          background-position: 20px -765px;
        }

        &-blog a {
          background-position: 20px -850px;
        }

        &-community a {
          background-position: 20px -935px;
        }
      }
    }
  }

  &-footer {
    float: right;

    .menu-item {
      display: inline-block;
      margin-left: 35px;

      a {
        color: $clr-menu-link;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.profile-options {
  position: absolute;
  right: 20px;
  top: 0;

  .avatar {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border-radius: 50%;
    display: inline-block;
    height: 50px;
    margin-right: 10px;
    overflow: hidden;
    vertical-align: middle;
    width: 50px;

    @include media(portable) {
      height: 40px;
      width: 40px;
    }
  }

  .name {
    color: $clr-menu-link;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    position: relative;

    .profile-submenu {
      background: $clr-white;
      border-radius: 5px;
      box-shadow: 0 2px 45px 0 rgba(111, 35, 129, 0.24);
      display: none;
      flex-direction: column;
      list-style: none;
      padding: 0;
      position: absolute;
      right: 0;
      top: 50px;
      transition: color 0.3s ease-in-out;
      width: 160px;

      &::before {
        border-color: transparent transparent $clr-white transparent;
        border-style: solid;
        border-width: 0 7px 7px 7px;
        content: "";
        height: 0;
        position: absolute;
        right: 10px;
        top: -7px;
        width: 0;
      }

      &::after {
        content: "";
        height: 30px;
        left: 0;
        position: absolute;
        top: -30px;
        width: 100%;
      }

      .item {
        text-align: left;

        @include media(desktop) {
          &:not(:last-of-type) {
            border-bottom: 1px solid $clr-gray-200;
          }
        }

        &-mobile {
          display: none;

          @include media(portable) {
            display: block;
          }
        }

        &:hover a {
          color: $clr-purple;
        }

        a {
          color: $clr-menu-link;
          display: block;
          text-decoration: none;
          transition: color 0.3s ease-in-out;

          @include media(desktop) {
            padding: 10px 15px;
          }
        }

        @include media(portable) {
          font-size: 18px;
          padding: 5px 0;
        }
      }

      &.active {
        display: flex;
      }

      @include media(portable) {
        box-shadow: none;
        left: 0;
        position: relative;
        top: 0;
        width: 100%;
      }
    }

    @include media(desktop) {
      &:hover {
        color: $clr-purple;

        .profile-submenu {
          display: flex;
        }
      }
    }

    &::after {
      background: $clr-purple-175 url("../../images/svg/ico-arrow.svg") center center no-repeat;
      background-size: 10px 5px;
      border-radius: 50%;
      content: "";
      display: inline-block;
      height: 16px;
      margin-left: 10px;
      position: relative;
      top: 3px;
      width: 16px;
    }

    @include media(portable) {
      display: inline;
      width: 100%;

      &::after {
        position: absolute;
        right: -25px;
      }
    }
  }

  @include media(portable) {
    background: $clr-white;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09);
    line-height: 60px;
    order: 1 !important;
    padding: 0 20px;
    position: relative;
    right: unset;
    top: unset;
  }
}
