//================ Red ================
$clr-red-25: #fbeffe;
$clr-red-50: #fee4e4;
$clr-red-100: #e4b0ad;
$clr-red-300: #ca615c;
$clr-red-400: #ff6161;
$clr-red-500: #b72720;
$clr-red-600: #e02020;
$clr-red-700: #751915;
$clr-red-900: #320b09;
$clr-red: $clr-red-500;
//================ Purple ================
$clr-purple-25: #fefbff;
$clr-purple-50: #faecff;
$clr-purple-100: #f7f1f9;
$clr-purple-150: #faecfd;
$clr-purple-175: #fbeaff;
$clr-purple-200: #e49df5;
$clr-purple-250: #d027ff;
$clr-purple-300: #a351b7;
$clr-purple-400: #9238a6;
$clr-purple-500: #81109c;
$clr-purple-550: #6a3a75;
$clr-purple-600: #560e99;
$clr-purple-650: #321895;
$clr-purple-700: #530b64;
$clr-purple-800: #651a76;
$clr-purple-900: #24052b;
$clr-purple: $clr-purple-500;
//================ Blue ================
$clr-blue-100: #d3dffc;
$clr-blue-200: #acaec1;
$clr-blue-300: #5a5e84;
$clr-blue-400: #424c65;
$clr-blue-500: #1d2256;
$clr-blue-700: #131637;
$clr-blue-900: #080a18;
$clr-blue: $clr-blue-500;
//================ Green ================
$clr-green-50: #edfdec;
$clr-green-100: #c2e3c1;
$clr-green-300: #85c783;
$clr-green-400: #63a751;
$clr-green-500: #58b255;
$clr-green-600: #52c44e;
$clr-green-700: #397237;
$clr-green-900: #183118;
$clr-green: $clr-green-500;
//================Orange================
$clr-orange-50: #fef9e1;
$clr-orange-75: #fefae6;
$clr-orange-100: #ffecb3;
$clr-orange-200: #ffe082;
$clr-orange-300: #ffd54f;
$clr-orange-400: #e1b641;
$clr-orange-500: #ffc107;
$clr-orange-600: #faac2f;
$clr-orange-700: #ffa000;
$clr-orange-800: #816c12;
$clr-orange-900: #ff6f00;
$clr-orange: $clr-orange-500;
//================ White ================
$clr-gray-50: #fcfcfc;
$clr-gray-100: #f9f9f9;
$clr-gray-150: #f6f6f6;
$clr-gray-200: #e4e4e4;
$clr-gray-300: #cacaca;
$clr-gray-500: #b7b7b7;
$clr-gray-600: #a2a2a2;
$clr-gray-700: #757575;
$clr-gray-800: #838383;
$clr-gray-900: #323232;
$clr-gray: $clr-gray-500;
$clr-white: #fff;
$clr-white-a15: rgba($clr-white, .15);
$clr-black: #000;
$clr-black-a30: rgba($clr-black, .3);
$clr-font: #333;
$clr-menu-link: #1d2d41;
$clr-main-light: #596679;


$gradient-right: rgba($clr-purple, .5);
$gradient-left: rgba(86, 14, 153, .5);
$base-font-family: "Lato", Arial, Helvetica, sans-serif;
$primary-light: rgba($clr-black, .3);
%shadow {
  box-shadow: 0 4px 5px 0 fade-out($clr-black, .86), 0 1px 10px 0 fade-out($clr-black, .88), 0 2px 4px -1px fade-out($clr-black, .7);
}
%shadow-light {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
}
$pie-chart-purple: #d027ff;
$pie-chart-purple-active: #FF76FF;
$pie-chart-orange: #FA6400;
$pie-chart-orange-active: #FFA06E;
$pie-chart-yellow: #F7B501;
$pie-chart-yellow-active: #FFEE6E;
$pie-chart-blue: #37A1FF;
$pie-chart-blue-active: #7BD8FF;
$pie-chart-red: #ff3737;
$pie-chart-red-active: #FF827F;
$facebook: #3b5998;
$twitter: #00aced;
$google: #c32f10;
$linkedin: #0976b4;